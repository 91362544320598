.dialog[open] {
	font-size: var(--text-sm);
	margin: auto;
	background: var(--color-light);
	width: 50rem;
	box-shadow: var(--shadow-2xl);
	border-radius: var(--rounded);
}

.dialog::backdrop {
	background: rgba(0, 0, 0, 0.7);
}

body:has(.dialog[open]) {
	overflow: hidden;
}

.dialog-form {
	position: relative;
	accent-color: var(--color-blue-700);
}

.dialog-form .field + .field {
	margin-top: var(--spacing-6);
}

.dialog-form .label {
	display: block;
	font-weight: var(--font-bold);
	margin-bottom: var(--spacing-2);
}

.dialog-form label abbr {
	text-decoration: none;
	color: var(--color-red-600);
	margin-left: 0.125rem;
	display: none;
}

.dialog-form .field:has(*:invalid) label abbr {
	display: inline;
}

.dialog-form .input {
	height: 2.25rem;
	background: var(--color-white);
	padding: var(--spacing-2);
	border-radius: var(--rounded-sm);
	box-shadow: 0px 0px 0px 1px var(--color-border);
}
.dialog-form textarea.input {
	height: auto;
	resize: none;
}
.dialog-form
	:where(input:not([type="checkbox"], [type="radio"]), select, textarea):focus {
	outline: 2px solid var(--color-blue-700);
}

.dialog-form select.input {
	appearance: none;
	font-size: inherit;

	padding-inline-end: 1.5rem;
	background-image: url("../../icons/arrow-drop-down.svg");
	background-repeat: no-repeat;
	background-position: right var(--spacing-1) top 50%;
	background-size: 24px;
}

.dialog-form .checkbox {
	min-height: 2.25rem;
	display: flex;
	align-items: center;
	color: var(--color-black);
	padding: var(--spacing-2) var(--spacing-3);
	background: var(--color-white);
	border-radius: var(--rounded-sm);
	box-shadow: 0px 0px 0px 1px var(--color-border);
	gap: var(--spacing-3);
	cursor: pointer;
}

.dialog .buttons {
	margin-top: var(--spacing-8);
	display: flex;
	gap: 0.75rem;
}

.dialog .buttons .btn {
	flex-basis: 50%;
	flex-grow: 1;
}
