.overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	display: grid;
	place-items: center;
	width: 100%;
	height: 100%;
	background: var(--color-backdrop);
}
dialog.overlay {
	display: none;
}
dialog.overlay[open] {
	display: grid;
}
