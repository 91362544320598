.code-token.code-punctuation,
.code-token.code-comment,
.code-token.code-doctype {
	color: var(--color-gray-500);
}
.code-token.code-tag,
.code-token.code-markup,
.code-token.code-variable,
.code-token.code-this,
.code-token.code-selector,
.code-token.code-key,
.code-token.code-kirbytag-bracket,
.code-token.code-prolog,
.code-token.code-delimiter {
	color: var(--color-red-500);
}
.code-token.code-constant,
.code-token.code-number,
.code-token.code-boolean,
.code-token.code-boolean.code-important,
.code-token.code-attr-name,
.code-token.code-kirbytag-attr,
.code-token.code-kirbytag-name,
.code-token.code-entity,
.code-token.code-bold,
.code-token.code-bold > .code-punctuation {
	color: var(--color-orange-500);
}
.code-token.code-keyword,
.code-token.code-italic,
.code-token.code-italic > .code-punctuation {
	color: var(--color-purple-500);
}
.code-token.code-function {
	color: var(--color-blue-500);
}
.code-token.code-operator,
.code-token.code-title {
	color: var(--color-aqua-500);
}
.code-token.code-string,
.code-token.code-attr-value,
.code-token.code-attr-value .code-punctuation,
.code-token.code-list.code-punctuation {
	color: var(--color-green-500);
}
.code-token.code-scope,
.code-token.code-class-name,
.code-token.code-property,
.code-token.code-url {
	color: var(--color-yellow-500);
}
.code-token.code-title,
.code-token.code-kirbytag-bracket,
.code-token.code-list.code-punctuation,
.code-token.code-bold {
	font-weight: var(--font-bold);
}
.code-token.code-title .code-punctuation {
	color: var(--color-gray-300);
}
.code-token.code-italic {
	font-style: italic;
}
