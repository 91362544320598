@media screen and (min-width: 48rem) {
	.with-sidebar {
		display: grid;
		grid-gap: 3rem;
		grid-template-columns: repeat(4, 1fr);
	}
	.with-sidebar > :last-child {
		grid-column-start: 2;
		grid-column-end: -1;
	}
	.with-sidebar > article {
		width: 100%;
	}

	.with-sidebar .sidebar-mobile-select {
		display: none;
	}
}

@media screen and (max-width: 48rem) {
	.with-sidebar .sidebar-header {
		display: flex;
		align-items: center;
		background: var(--color-light);
		border-radius: var(--rounded);
	}
	.with-sidebar .sidebar-header .h1 {
		font-weight: var(--font-semibold);
		font-size: var(--text-sm);
		color: var(--color-black);
		margin-bottom: 0;
	}
	.with-sidebar .sidebar-header .h1 a {
		border-right: 1px solid var(--color-border);
		padding: var(--spacing-2);
	}

	.with-sidebar .sidebar :where(.sidebar-group, ul) {
		display: none;
	}
}
