:root {
	--box-default: var(--color-light);
	--box-alert: var(--color-red-400);
	--box-info: var(--color-blue-400);
	--box-success: var(--color-green-400);
	--box-warning: var(--color-orange-400);
}

.box {
	position: relative;
	--bg: var(--box-default);
	background: var(--bg);
	border-radius: var(--rounded);
	overflow: hidden;
}
.box .box-text {
	color: var(--color-black);
	padding: var(--spacing-6);
	margin-right: var(--spacing-6);
}
.box .box-icon {
	color: var(--bg);
	position: absolute;
	top: 0;
	right: 0;
}
.box--alert {
	--bg: var(--box-alert);
}
.box--info {
	--bg: var(--box-info);
}
.box--success {
	--bg: var(--box-success);
}
.box--warning {
	--bg: var(--box-warning);
}
