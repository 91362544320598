:where(svg) {
	fill: currentColor;
}

:where(svg[viewBox="0 0 24 24"]),
:where(svg[viewBox="0 0 16 16"]) {
	width: 18px;
	height: 18px;
}

svg[data-type="loader"] {
	animation: Spin 1.5s linear infinite;
}

@keyframes Spin {
	100% {
		transform: rotate(360deg);
	}
}
