.footer .container {
	padding-top: var(--spacing-36);
}
.footer .flex {
	justify-content: space-between;
	gap: var(--spacing-12);
}

@media screen and (max-width: 50rem) {
	.footer .flex {
		flex-direction: column;
	}
}

.footer-info {
	max-width: 10rem;
}
.footer-info .social {
	margin-left: -0.375rem;
}

.footer-menu a {
	display: block;
	padding: 0.1rem 0;
}
.footer-menu-partners a {
	padding: 0.25rem 0;
}
.footer-menu-partners svg {
	width: auto;
	width: 6.5rem;
}
