:root {
	--spacing-0: 0;
	--spacing-1: 0.25rem;
	--spacing-2: 0.5rem;
	--spacing-3: 0.75rem;
	--spacing-4: 1rem;
	--spacing-6: 1.5rem;
	--spacing-8: 2rem;
	--spacing-12: 3rem;
	--spacing-16: 4rem;
	--spacing-24: 6rem;
	--spacing-36: 9rem;
	--spacing-48: 12rem;

	/** @deprecated */
	--spacing-px: 1px;
	--spacing-2px: 2px;
	--spacing-5: 1.25rem;
	--spacing-10: 2.5rem;
	--spacing-20: 5rem;
}
