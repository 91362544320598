[style*="--aspect-ratio"] {
	aspect-ratio: var(--aspect-ratio);
	object-fit: cover;
}

@supports not (aspect-ratio) {
	[style*="--aspect-ratio"] {
		position: relative;
	}
	[style*="--aspect-ratio"]::before {
		content: "";
		display: block;
		padding-bottom: calc(100% / (var(--aspect-ratio)));
	}
	[style*="--aspect-ratio"] > :first-child {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
