:root {
	--btn-bg: var(--color-black);
	--btn-color: var(--color-black);
	--btn-filled-bg: var(--color-black);
	--btn-filled-color: var(--color-white);
}

button {
	overflow: hidden;
	text-overflow: ellipsis;
}

.btn {
	display: inline-flex;
	padding: 0.5rem 1.5rem;
	align-items: center;
	justify-content: center;
	font-size: var(--text-sm);
	color: var(--btn-color);
	cursor: pointer;
	border: 1px solid transparent;
	border-radius: var(--rounded);
	font-family: var(--font-mono);
	white-space: nowrap;
	background: none;
	position: relative;
}
.btn svg {
	margin-right: 0.75rem;
	min-width: 1rem;
}
.btn--outlined {
	border-color: var(--color-gray-400);
}
.btn--outlined.btn--white {
	border-color: var(--color-white);
	color: var(--color-white);
}
.btn--filled {
	border-color: var(--btn-filled-bg);
	background: var(--btn-filled-bg);
	color: var(--btn-filled-color) !important;
}
.btn--filled svg {
	color: var(--color-yellow-600);
}
