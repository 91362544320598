.logo {
	display: flex;
	padding: var(--spacing-3);
}
.logo svg {
  display: flex;
	width: 36px;
	height: 42px;
}

.logo + .version.archived .link {
	text-decoration-color: var(--color-gray-400);
}
