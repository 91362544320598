:root {
	--container: 90rem;
	--container-padding: var(--spacing-4);

	--spacing-20: 5rem;
	--spacing-42: 10.5rem;

	--leading-none: 1;
	--leading-tight: 1.25;
	--leading-snug: 1.375;
	--leading-normal: 1.5;
	--leading-relaxed: 1.625;
	--leading-loose: 2;

	--text-h1: var(--text-4xl);

	--shadow-2xl: rgba(0, 0, 0, 0.025) 0 0 10px, rgba(0, 0, 0, 0.075) 0 5px 40px,
		rgba(0, 0, 0, 0.075) 0 30px 100px;
}

@media screen and (min-width: 22rem) {
	:root {
		--container-padding: var(--spacing-6);
	}
}
@media screen and (min-width: 30rem) {
	:root {
		--container-padding: var(--spacing-12);
	}
}
@media screen and (min-width: 40rem) {
	:root {
		--text-h1: var(--text-5xl);
	}
}
@media screen and (min-width: 72rem) {
	:root {
		--container-padding: var(--spacing-24);
	}
}
