:root {
	--shadow-sm: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.025);
	--shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.05);
	--shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	--shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.05);

	--shadow: var(--shadow-sm);
	--shadow-toolbar: rgba(0, 0, 0, 0.1) -2px 0 5px, var(--shadow),
		var(--shadow-xl);

	/** @deprecated */
	--shadow-outline: var(--color-focus, currentColor) 0 0 0 2px;
	--shadow-inset: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	--shadow-sticky: rgba(0, 0, 0, 0.05) 0 2px 5px;
	--box-shadow-dropdown: var(--shadow-dropdown);
	--box-shadow-item: var(--shadow);
	--box-shadow-focus: var(--shadow-xl);
	--shadow-dropdown: var(--shadow-lg);
	--shadow-item: var(--shadow-sm);
}
