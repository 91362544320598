.filters a,
.filters button {
	display: flex !important; /* normal menus use display: block */
	align-items: center;
	margin-bottom: var(--spacing-1);
}
.filters svg {
	margin-right: var(--spacing-3);
}
.filters hr {
	margin: var(--spacing-3) 0;
}
