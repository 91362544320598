/** BANNER **/
.banner {
	display: none;
	position: absolute;
	padding: 0.5rem 0.75rem;
	line-height: var(--leading-none);
	white-space: nowrap;
	border: 1px solid var(--color-gray-200);
}

/** Small **/
@media screen and (max-width: 60rem) {
	.banner {
		display: block;
		top: 7rem;
	}
	.banner a::after {
		content: " →";
	}
}

/** Large **/
@media screen and (min-width: 60rem) {
	.banner {
		display: block;
		top: 5rem;
		right: 0;
		background: var(--color-white);
	}
	.banner::before {
		position: absolute;
		top: -7.5px;
		right: calc(4.5rem - 1.5px);
		content: "";
		border-left: 7.5px solid transparent;
		border-right: 7.5px solid transparent;
		border-bottom: 7.5px solid var(--color-gray-200);
	}
	.banner::after {
		position: absolute;
		top: -6px;
		right: 4.5rem;
		content: "";
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid var(--color-white);
	}
}
